import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>This is just a short update. Remember my last post where I presented the final design? Well, I should not have called it final before testing it in the car itself <Emoji e="😉" />.
        While doing so I discovered a whine played over the speakers that rose and fell with the RPM of the car. With more and more cars 
        faking engine sounds over the speakers, you could call this a feature instead of a bug. In any case, it's not what I wanted, so back to the drawing board. </FirstP>
         
        <p>
            As a recap, I have had two working versions of the PCB. The first one did not have these noise issues, since I isolated the ground of the audio circuits to remove possible ground loops.
            In the 'final' version I removed this isolated DC/DC converter as an experiment, because I was not sure if this was overkill or not. 
            I thought I was happy with the result, but this was only from testing at my desk. When I actually put the circuit board in my car, this alternator whine appeared.            
        </p>

        <p>
            The good news is that my initial choice to use this component was a good one. The bad news is that I never should have removed it.
        </p>

        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="Final result" />
        <Caption>The Recom R1SX-0505 was used to isolate the grounds</Caption>

        <p>The advantage of the component I selected is that it is easy to integrate, is SMD solderable, and does not require a lot of external components. 
            The disadvantage is that it is rather large, and that I repositioned everything on the board when I removed it to make the package more compact <Emoji e="🙄" />.</p><p>
            So since there was no quick fix to bring it back, I took the opportunity to redo the PCB from the ground up in KiCad rather than CircuitMaker which I used for 
            previous iterations. When using KiCad as an experiment while designing another PCB I quickly found out that it gives a much better experience, at least for me.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Final result" />
        <Caption>Will this really be the final design?</Caption>

        <p>
            So with that, I took all the changes from the latest design, re-introduced the DC/DC converter, an rearranged everything to optimize the available space.
            I also tried to follow the guideline that no traces should cross any splits in ground or power planes. To make this possible, I converted the board to 
            a four layer board to use a dedicated layer to the ground and a dedicated layer for power.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Ground planes" />
        <Caption>The ground planes for analog and digital are separated by the Isolated DC/DC converter</Caption>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Power planes" />
        <Caption>Different power planes are used for 5V, 3.3V and isolated 5V.</Caption>

        <p>
            I still need to order and manufacture this latest update, but at least the design phase is finished. I really hope that this time everything will work how it is intended,
            because other than the noise issue in the car the PCB was working perfectly fine. Software integration is close to finished, and I have not encountered any issues 
            with overheating or robustness. I will keep you posted on these pages!
        </p>
       
    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "pcbrender.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "groundlayer.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img3: file(relativePath: { eq: "powerlayer.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "recom.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
